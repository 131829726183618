import gql from 'graphql-tag';

export default gql`
	fragment Member on Member {
		ID
		Email
		Surname
		FirstName
		MiddleName
		Phone
	}
`;
