import gql from 'graphql-tag';
import Invoice from '../../../fragments/Invoice';
import { getClient } from '../../../utils/apollo';
import { deleteTypeName, diff } from '../../../utils/objects';
import { isRelatedObjectDefined } from '../../../utils/bookable';

const createInvoiceMutation = gql`
    ${Invoice}
    mutation CreateInvoice($input: CreateCMInvoiceInput!) {
        createCMInvoice(input: $input) {
            ...Invoice
        }
    }
`;

const updateInvoiceMutation = gql`
    ${Invoice}
    mutation UpdateInvoice($input: UpdateCMInvoiceInput!) {
        updateCMInvoice(input: $input) {
            ...Invoice
        }
    }
`;

const submitToXeroMutation = gql`
    ${Invoice}
    mutation SubmitToXero($id: ID!) {
        submitCMInvoiceToXero(ID: $id) {
            ...Invoice
        }
    }
`;

const authoriseToXeroMutation = gql`
    ${Invoice}
    mutation AuthoriseToXero($id: ID!) {
        authoriseCMInvoiceToXero(ID: $id) {
            ...Invoice
        }
    }
`;

export const createInvoiceFunc = async invoice => {
    return await getClient().mutate({
        mutation: createInvoiceMutation,
        variables: {
            input: createInvoice(invoice)
        }
    });
};

export const updateInvoiceFunc = async (invoice, original) => {
    return await getClient().mutate({
        mutation: updateInvoiceMutation,
        variables: {
            input: trimInvoice(invoice, original)
        }
    });
};

export const submitToXeroFunc = async invoice => {
    return await getClient().mutate({
        mutation: submitToXeroMutation,
        variables: {
            id: invoice.ID
        }
    });
};

export const authoriseToXeroFunc = async invoice => {
    return await getClient().mutate({
        mutation: authoriseToXeroMutation,
        variables: {
            id: invoice.ID
        }
    });
};

const trimInvoice = (update, original) => {
    // create a clone with any changes, so data is not disturbed during save or lost on failure.
    let invoice = JSON.parse(JSON.stringify(original ? diff({ ...update }, original) : { ...update }));

    if (!!invoice.CremationOrder && Number(invoice.CremationOrder.ID) > 0) {
        invoice.CremationOrderID = invoice.CremationOrder.ID;
        delete invoice.CremationOrder;
    }

    if (!!invoice.PlaqueOrder && Number(invoice.PlaqueOrder.ID) > 0) {
        invoice.PlaqueOrderID = invoice.PlaqueOrder.ID;
        delete invoice.PlaqueOrder;
    }

    deleteTypeName(invoice);

    if (invoice.InvoiceItems) {
        for (let x = 0; x < invoice.InvoiceItems.length; x++) {
            let invoiceItem = invoice.InvoiceItems[x];
            delete invoiceItem.QuoteID;
            deleteTypeName(invoiceItem);
        }
        invoice.InvoiceItems = invoice.InvoiceItems.filter(item => item.Quantity > 0);
    }

    if (isRelatedObjectDefined(original)) {
        invoice.ID = original.ID;
        if (invoice.Customer && isRelatedObjectDefined(original.Customer)) {
            invoice.Customer.ID = original.Customer.ID;
            invoice.Customer.Postcode = !!invoice.Customer.Postcode ? Number(invoice.Customer.Postcode) : null;
            delete invoice.Customer.Name; // AddressAutocomplete adds it
        }
    }

    return { ...invoice };
};

const createInvoice = newInvoice => {
    // create a clone, so data is not disturbed during save or lost on failure.
    let invoice = JSON.parse(JSON.stringify({ ...newInvoice }));

    //    delete invoice.Customer.BillingSource;
    if (invoice.InvoiceItems) {
        for (let x = 0; x < invoice.InvoiceItems.length; x++) {
            let invoiceItem = invoice.InvoiceItems[x];
            delete invoiceItem.ID;
            delete invoiceItem.QuoteID;
            deleteTypeName(invoiceItem);
        }
        invoice.InvoiceItems = invoice.InvoiceItems.filter(item => item.Quantity > 0);
    }
    if (invoice.Customer) {
        invoice.Customer.Postcode = !!invoice.Customer.Postcode ? Number(invoice.Customer.Postcode) : null;
        delete invoice.Customer.Name; // AddressAutocomplete adds it
    }
    return { ...invoice };
};
