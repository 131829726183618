import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'react-apollo/index';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from './TextField';
import Spinner from '../loading/Spinner';
import { getClient } from '../../utils/apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import SearchIcon from '../icon/SearchIcon';
import { getValidationDecorations } from '../../utils/validation';

/**
 * Autocomplete that supports searching for and selecting results from
 * the gql database.
 *
 * For custom handling behavior you can provide the `value` and `onSelect`
 * callback handler passed through props.
 *
 * To set the fields that will be displayed, you can specify labelField
 * To set the field that will be used as the ID query, specify valueField
 */

class GqlAutocomplete extends Component {
    static defaultProps = {
        value: null, // Object like { ID: 1, Name: "Hospital" }
        onSelect: undefined,
        placeholder: 'Search...',
        categories: undefined, //Object like [ { key:'', label:'' } ],
        readAllQuery: undefined, //query to read ALL items from database
        readOneQuery: undefined //query to read single item by ID from the database
    };

    state = {
        value: null,
        searchTerm: '',
        focused: false,
        labelField: 'Name',
        valueField: 'ID',
        labelFieldFunc: null,
        valueIsListed: false
    };

    componentWillMount() {
        const state = this.state;
        const valueField = this.getValueOrDefault('valueField', state, this.props);
        const labelField = this.getValueOrDefault('labelField', state, this.props);
        const labelFieldFunc = this.getValueOrDefault('labelFieldFunc', state, this.props);
        this.setState({ valueField, labelField, labelFieldFunc });
        this.resetInitialState(this.props, true);
    }

    componentWillReceiveProps(nextProps) {
        this.resetInitialState(nextProps, false);
    }

    componentDidUpdate(_, { searchTerm }) {
        const { searchTerm: lastSearchTerm } = this.state;
        if (searchTerm !== lastSearchTerm) this.updateForm();
    }

    render() {
        const { readAllQuery, convertResults, client, name } = this.props;
        const { focused } = this.state;
        const variables = this.getQueryVariables();

        return (
            <Query
                query={readAllQuery}
                skip={!focused || (!variables.contains && !variables.key)}
                variables={variables}
                context={{ debounceKey: 2 }}
                client={client}
            >
                {({ data, loading, error }) => {
                    let results = null;
                    if (data) {
                        const propertyName = Object.keys(data)[0];
                        results = convertResults
                            ? convertResults(data)
                            : data[propertyName]
                            ? data[propertyName].edges.map(e => e.node)
                            : null;
                    }

                    const isReallyLoading = results != null && loading && focused; //it seems to loading=true before a query is run
                    return (
                        <Downshift id={name}>
                            {d => this.renderContainer(results, isReallyLoading, d, error)}
                        </Downshift>
                    );
                }}
            </Query>
        );
    }

    renderContainer(results, loading, downshiftData, error) {
        const { className } = this.props;
        return (
            <div className={`gql-autocomplete autocomplete ${className || ''}`}>
                {this.renderInput(downshiftData, loading)}
                {this.onReceiveData(results, loading, downshiftData, error)}
            </div>
        );
    }

    renderInput(downshiftData, loading) {
        const { placeholder, id, name, label, multiple, required, allowUnlisted, variant } = this.props;
        const { searchTerm } = this.state;

        const onChange = allowUnlisted
            ? e =>
                  this.setState({
                      searchTerm: e.target.value,
                      valueIsListed: false,
                      value: e.target.value
                  })
            : e =>
                  this.setState({
                      searchTerm: e.target.value,
                      valueIsListed: false
                  });

        const decorations = getValidationDecorations(this.props);

        return (
            <div className={variant}>
                <TextField
                    name={name || id}
                    fullWidth
                    label={label}
                    multiple={multiple}
                    required={required}
                    validationResult={decorations.validationResult}
                    InputProps={{
                        disableUnderline: true,
                        ...downshiftData.getInputProps({ placeholder }),
                        startAdornment: (
                            <InputAdornment position="start">
                                {loading ? (
                                    <span style={{ marginRight: 8 }}>
                                        <Spinner size="xs" />
                                    </span>
                                ) : (
                                    <SearchIcon className="input-search" />
                                )}
                            </InputAdornment>
                        ),
                        inputProps: {
                            ref: this.onInputRef,
                            onChange,
                            value: searchTerm || '',
                            onFocus: this.onInputFocus,
                            onBlur: this.onInputBlur
                        }
                    }}
                />
            </div>
        );
    }

    onReceiveData(results, loading, downshiftData, error) {
        const { focused, searchTerm } = this.state;
        if (!focused || !searchTerm) return false;

        return (
            <Paper className="hover-search-results" square>
                {this.renderResults(results, loading, downshiftData, error)}
            </Paper>
        );
    }

    renderResults(results, loading, downshiftData, error) {
        if (error) return <div className="item">{`An Error occurred: ${error}`}</div>;

        if ((results || []).length === 0) return <div className="item">No Results</div>;

        if (loading) return <Spinner size="xs" />;

        return <Fragment>{results.map((r, i) => this.renderResult(r, i, downshiftData))}</Fragment>;
    }

    renderResult = (result, index, downshiftData) => {
        const { extraLabelField } = this.props;
        const { valueField, labelField, labelFieldFunc } = this.state;

        const itemProps = downshiftData.getItemProps({
            item: result[valueField]
        });

        return (
            <MenuItem
                {...itemProps}
                className="item"
                selected={downshiftData.highlightedIndex === index}
                key={result[valueField]}
                onClick={() =>
                    this.onItemSelected(this.createEvent('itemSelected'), {
                        ...result
                    })
                }
            >
                <span className="item-label">
                    {labelFieldFunc ? labelFieldFunc(result) : result[labelField]}
                    {extraLabelField && (
                        <small style={{ marginLeft: 'auto', paddingLeft: 12 }}>{result[extraLabelField]}</small>
                    )}
                </span>
            </MenuItem>
        );
    };

    createEvent = name => {
        var e = document.createEvent('Event');
        e.initEvent(name, true, true);
        return e;
    };

    resetInitialState(nextProps, isMount) {
        const { name, form, findById, readOneQuery, categories } = nextProps;

        const nextValue = (form && form.getState(name)) || nextProps.value;
        const { value: stateValue } = this.state;

        //on first call, we don't need to compare props. just set the initial state
        //on subsequent calls, we're comparing old props to new props
        if (!isMount) {
            //prime the first value
            const { form: lastForm } = this.props;
            const lastValue = (lastForm && lastForm.getState(name)) || this.props.value || null;
            if (nextValue === lastValue && nextValue === stateValue) return;
        }

        if (findById && readOneQuery) {
            if (nextValue) {
                var that = this;
                const { client: queryClient } = this.props;
                getValueById(queryClient, nextValue, readOneQuery, categories).then(result => {
                    if (!result) return;
                    const clonedResult = { ...result };
                    that.setState({ value: clonedResult });
                    that.setSearchTerm(clonedResult, nextProps.labelField, nextProps.labelFieldFunc);
                });
            }
        } else if (nextValue) {
            this.setState({ value: nextValue });
            this.setSearchTerm(nextValue, nextProps.labelField, nextProps.labelFieldFunc);
        }
    }

    setSearchTerm = (newValue, newLabelField, newLabelFieldFunc) => {
        const { labelField, labelFieldFunc, searchTerm } = this.state;

        if (!newLabelField) newLabelField = labelField;
        if (!newLabelFieldFunc) newLabelFieldFunc = labelFieldFunc;

        const lastSearchTerm = searchTerm;

        let newSearchTerm;
        if (typeof newValue === 'object') {
            if (newLabelFieldFunc) {
                newSearchTerm = newLabelFieldFunc(newValue);
            } else {
                newSearchTerm = newValue.hasOwnProperty(newLabelField) ? newValue[newLabelField] : null;
            }
        } else {
            newSearchTerm = newValue;
        }

        if (lastSearchTerm === newSearchTerm) return;

        this.setState({ searchTerm: newSearchTerm });
    };

    getValueOrDefault(name, state, props) {
        const defaultValue = state ? state[name] : null;
        const propValue = props ? props[name] : null;
        return propValue || defaultValue;
    }

    onInputRef = input => {
        this.input = input;
    };

    onInputFocus = () => {
        this.setState({ focused: true });
    };

    onInputBlur = () => {
        this.setState({ focused: false });
    };

    updateForm() {
        const { name, form, allowUnlisted } = this.props;
        const { value, valueIsListed } = this.state;

        if (!form || (!allowUnlisted && !valueIsListed)) return;

        if (form.setField && !form.setState) form.setState = form.setField;

        form.setState({ [name]: value });
    }

    onItemSelected = (e, result) => {
        const { onSelect, findById, clearOnSelect, form, name } = this.props;

        if (onSelect) onSelect(e, result);

        if (e.defaultPrevented) return;

        if (clearOnSelect) {
            this.setState({ value: null, searchTerm: '', valueIsListed: true });
        } else {
            const { valueField } = this.state;
            const valueToUse = findById && result ? result[valueField] : result;
            this.setState({ value: valueToUse, valueIsListed: true });
            this.setSearchTerm(valueToUse);
        }

        if (this.input) this.input.blur();

        if (form && !form.getValidation) {
            console.warn(name + ' form does not contain validation function!');
            return;
        }

        if (form && name && form.getValidation(name, true).shouldUpdate) this.forceUpdate();
    };

    getQueryVariables() {
        const { categories, canSearchByKey, extraVariables } = this.props;
        const { searchTerm } = this.state;

        let variables = {
            limit: 10,
            filter: categories,
            ...extraVariables
        };

        const term = searchTerm ? searchTerm.trim() : '';

        if (canSearchByKey && !isNaN(parseInt(term, 10))) {
            // If the term is non-numeric then we do a `contains` search
            // otherwise we search for a `key` match
            variables.key = term;
            variables.contains = null;
        } else {
            variables.key = null;
            variables.contains = term;
        }

        return variables;
    }
}

export const getValueById = async (queryClient, objOrID, readOneQuery, categories = []) => {
    const client = queryClient || getClient();
    const ID = objOrID.id === undefined ? objOrID : objOrID.id;
    if (!ID) return null;
    const asyncQuery = await client.query({
        query: readOneQuery,
        variables: { id: ID, filter: categories }
    });

    const data = asyncQuery.data;
    const propertyName = Object.keys(data)[0];

    if (data[propertyName] && data[propertyName].edges) {
        const { convertResults } = this.props;
        const { valueField } = this.state;
        const results = convertResults ? convertResults(data) : data[propertyName].edges.map(e => e.node);
        return results.find(x => x[valueField] === ID);
    }

    return data[propertyName];
};

// prettier-ignore
export default compose(
	withSnackbarMessage,
	withStyles({})
)(GqlAutocomplete);
