import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

/**
 * Send to printer.
 * @param {function} trigger is a function that returns a clickable object (e.g. button) to trigger printing.
 * @param {HTMLElement} children are just things to print out.
 * @param {function} content is a function that returns a RefObject for a thing to print out (instead of children.)
 * @param {string} pageStyle is a string of additional print styles.
 */
export default function PrintContent({ trigger, content, pageStyle, children }) {
    const contentRef = useRef(null);
    const reactToPrintContent = () => {
        return content ? content() : contentRef.current; // lazy-loaded function to give refs a chance to render
    };
    const handlePrint = useReactToPrint({ contentRef, pageStyle, preserveAfterPrint: true });
    const triggerButton = React.cloneElement(trigger(), { onClick: () => handlePrint(reactToPrintContent) });

    return (
        <>
            {triggerButton}
            <div style={{ display: 'none' }}>
                <div ref={contentRef}>{children}</div>
            </div>
        </>
    );
}
